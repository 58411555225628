import { labels } from "./common/labes";
import LocalStorage from "./utilities/localStorage";

const clientId = LocalStorage.getItem("client")?.id;

export const API_SERVER = process.env.REACT_APP_API_SERVER;

export const PAN_API_KEY = process.env.REACT_APP_PAN_API_KEY;

export const PAN_AGENCY_KEY = process.env.REACT_APP_PAN_AGENCY_KEY;

export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

export const Companies = clientId
  ? labels(clientId, "sidebarCompany")
  : "Companies";
export const Company = clientId ? labels(clientId, "addAndTable") : "Company";
export const company = clientId
  ? labels(clientId, "addAndTable")?.toLocaleLowerCase()
  : "company";
