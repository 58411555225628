import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { signin, tenantCheck } from "./loginAPI";
import LocalStorage from "../../../utilities/localStorage";
import { history } from "../../../app/history";

const initialState = {
  loading: false,
  token: "",
  user: null,
  first_name: "",
  last_name: "",
  company_name: "",
  contact_person_email: "",
  PAN_number: "",
  legal_name: "",
  is_domestic: "",
  roles: [],
  vendor_id: "",
  tenantData: {},
  client: {},
};

const actions = {
  LOGIN: "login/LOGIN",
  TENANT: "tenant/TENANT",
};

export const login = createAsyncThunk(actions.LOGIN, async (payload) => {
  const response = await signin(payload);

  if (response?.token) {
    const {
      token,
      user_id,
      first_name,
      last_name,
      company_name,
      contact_person_email,
      PAN_number,
      legal_name,
      is_domestic,
      roles,
      vendor_id,
    } = response;
    let newUser = {
      token,
      user_id,
      first_name,
      last_name,
      company_name,
      contact_person_email,
      PAN_number,
      legal_name,
      is_domestic,
      roles,
      vendor_id,
    };

    /* history.push("/app"); */
    LocalStorage.setItem("tata_login_user", JSON.stringify(newUser));
    // eslint-disable-next-line array-callback-return

    if (
      newUser.roles[0] === "super admin" ||
      newUser.roles[0] === "finance" ||
      newUser.roles[0] === "Admin"
    ) {
      history.push("/app/recomm-vcc");
    } else if (newUser.roles[0] === "officer") {
      history.push("/app/orders-list");
    } else if (newUser.roles[0] === "user") {
      history.push("/app");
    } else if (newUser.roles.includes("support")) {
      history.push("/app/support/tenant");
    }
  }
  return response;
});

export const tenant = createAsyncThunk(actions.TENANT, async (payload) => {
  const response = await tenantCheck(payload);
  return response;
});

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    resetLoginDetails: (state) => {
      state.token = "";
      state.user_id = null;
      state.first_name = "";
      state.last_name = "";
      state.company_name = "";
      state.contact_person_email = "";
      state.PAN_number = "";
      state.legal_name = "";
      state.is_domestic = "";
      state.roles = [];
      state.vendor_id = "";
    },
    TenantDetails: (state, action) => {
      state.client = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        const {
          token,
          message: msg,
          first_name,
          user_id,
          last_name,
          company_name,
          contact_person_email,
          PAN_number,
          legal_name,
          is_domestic,
          roles,
          vendor_id,
        } = action.payload;

        state.loading = false;
        state.token = token;
        state.user_id = user_id;
        state.first_name = first_name;
        state.last_name = last_name;
        state.company_name = company_name;
        state.contact_person_email = contact_person_email;
        state.PAN_number = PAN_number;
        state.legal_name = legal_name;
        state.is_domestic = is_domestic;
        state.roles = roles;
        state.vendor_id = vendor_id;
        if (token) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(login.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(tenant.pending, (state) => {
        state.loading = true;
      })
      .addCase(tenant.fulfilled, (state, action) => {
        const { success, data } = action.payload;

        if (success) {
          LocalStorage.setItem("client", data);
          state.client = data;
          state.loading = false;
        } else {
          message.error(data);
        }
      })
      .addCase(tenant.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  },
});
export const loginActions = loginSlice.actions;
export const { resetLoginDetails, TenantDetails } = loginSlice.actions;

export const logout = () => (dispatch) => {
  localStorage.clear();
  dispatch(resetLoginDetails());
  message.success("Thank you! You have logged out of your account");
  history.push("/login");
};

export const clientClear = () => (dispatch) => {
  localStorage.clear();
  dispatch(TenantDetails());
};

export default loginSlice.reducer;
