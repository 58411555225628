/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledCard,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import {
  Box,
  CardContent,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { VendorCardTitle } from "../../userOrders/components/userOrdersViewContent";
import {
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { history } from "../../../app/history";
import { craeteCompany, subCompanyDropdown } from "../redux/companySlice";
import LocalStorage from "../../../utilities/localStorage";
import { Company, company } from "../../../CONSTANTS";
import { useEffect } from "react";
import AutoComplate, {
  GlobalAutocomplete,
} from "../../../common/sharedComponents/AutoComplate";
import { useGetAllSAPQuery } from "../../../utilities/redux-api/sap/sap_api";

function CompanyContent() {
  const { subCompanyDropdownList } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const ClientId = LocalStorage.getItem("client")?.id;
  const { data: dropdown } = useGetAllSAPQuery(null);
  const [loading, setLoading] = useState(false);
  const [isSubCompany, setIsSubCompany] = useState(false);
  const [parentId, setParentId] = useState(null);
  console.log("dropdown: ", dropdown);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const handleSave = (data) => {
    setLoading(true);

    let company_details = {
      legal_name: data.legal_name || data.company_name,
      company_name: data.company_name,
      contact_person_email: data.contact_person_email,
      permanent_account_number: data.permanent_account_number,
      company_email: data.company_email,
      company_code: data.company_code,
      company_location: data.company_location,
      sap_config_id: data.sap_name?.key,
      parent_id: parentId?.id,
    };

    dispatch(craeteCompany(company_details)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/company");
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    dispatch(subCompanyDropdown());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Add New {Company}
                </VendorCardTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Fill all details
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={3}>
              <Grid item md={6}>
                <VendorCardLabel>
                  {Company} Name<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder={`Enter ${Company} name`}
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="company_name"
                  {...register("company_name", {
                    required: `please enter ${Company} name`,
                  })}
                  error={Boolean(errors?.company_name)}
                  helperText={errors?.company_name?.message}
                />
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>{Company} Email</VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  type="email"
                  placeholder={`Enter ${company} email`}
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="company_email"
                  {...register("company_email", {
                    required: false,
                  })}
                  // onChange={(e) => allowOnlyValidCompanyEmail(e)}
                  // value={validCompanyEmail}
                />
              </Grid>
              {ClientId !== 5 ? (
                <>
                  <Grid item md={6}>
                    <VendorCardLabel>
                      Legal Name<Super>*</Super>
                    </VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      placeholder="Enter legal name"
                      size="small"
                      variant="standard"
                      style={{ width: "100%", paddingTop: "3px" }}
                      name="legal_name"
                      {...register("legal_name", {
                        required: false,
                      })}
                    />
                  </Grid>
                  {ClientId === 2 ? (
                    <>
                      <Grid item md={6}>
                        <VendorCardLabel>
                          {Company} Code<Super>*</Super>
                        </VendorCardLabel>
                        <TextField
                          sx={{
                            input: {
                              fontSize: "14px",
                              fontFamily: "var(--font-family-reg)",
                            },
                          }}
                          placeholder={`Enter ${Company.toLocaleLowerCase()} Code`}
                          size="small"
                          variant="standard"
                          style={{ width: "100%", paddingTop: "3px" }}
                          name="company_code"
                          {...register("company_code", {
                            required: `please enter ${Company.toLocaleLowerCase()} code`,
                          })}
                          error={Boolean(errors?.company_code)}
                          helperText={errors?.company_code?.message}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <VendorCardLabel>
                          {Company} Location<Super>*</Super>
                        </VendorCardLabel>
                        <TextField
                          sx={{
                            input: {
                              fontSize: "14px",
                              fontFamily: "var(--font-family-reg)",
                            },
                          }}
                          placeholder={`Enter ${company} Code`}
                          size="small"
                          variant="standard"
                          style={{ width: "100%", paddingTop: "3px" }}
                          name="company_location"
                          {...register("company_location", {
                            required: `please enter ${company} location`,
                          })}
                          error={Boolean(errors?.company_location)}
                          helperText={errors?.company_location?.message}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <GlobalAutocomplete
                          label={"Select SAP"}
                          name={"sap_name"}
                          isMultiple={false}
                          placeholder={"Select a SAP"}
                          style={{ width: "100%", paddingTop: "3px" }}
                          options={
                            dropdown?.map((company, index) => {
                              return {
                                key: company.id,
                                value: company?.sap_name,
                              };
                            }) ?? []
                          }
                          control={control}
                          rules={{ required: `SAP is required` }}
                          onSelect={() => {}}
                        />
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}

                  <Grid item md={6}>
                    <VendorCardLabel>
                      Contact Person Email<Super>*</Super>
                    </VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      placeholder="Enter conatct person email"
                      size="small"
                      variant="standard"
                      style={{ width: "100%", paddingTop: "3px" }}
                      name="contact_person_email"
                      {...register("contact_person_email", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Please enter valid email",
                        },
                        required: "Please enter email ",
                      })}
                      error={Boolean(errors?.contact_person_email)}
                      helperText={errors?.contact_person_email?.message}
                      // onChange={(e) => allowOnlyValidContactEmail(e)}
                      // value={validContactEmail}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <VendorCardLabel>
                      PAN Number<Super>*</Super>
                    </VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      placeholder="Enter PAN number"
                      size="small"
                      variant="standard"
                      style={{ width: "100%", paddingTop: "3px" }}
                      name="permanent_account_number"
                      {...register("permanent_account_number", {
                        pattern: {
                          value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                          message: "Please enter valid PAN",
                        },
                        required: "Please Enter PAN",
                      })}
                      error={Boolean(errors?.permanent_account_number)}
                      helperText={errors?.permanent_account_number?.message}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={6}>
                    <FormControl>
                      <VendorCardLabel>Is Sub {Company}</VendorCardLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        defaultValue="false"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                          onChange={() => setIsSubCompany(true)}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                          onChange={() => setIsSubCompany(false)}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {isSubCompany ? (
                    <Grid item md={6}>
                      <VendorCardLabel>
                        Select {Company} <Super>*</Super>
                      </VendorCardLabel>
                      <AutoComplate
                        data={subCompanyDropdownList}
                        placeholder={`Select ${Company.toLocaleLowerCase()}`}
                        title={"company_name"}
                        isMultiple={false}
                        setValue={setParentId}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Grid>
          </CardContent>
        </StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            Add {Company}
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default CompanyContent;
