import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  // IconButton,
  Grid,
  IconButton,
  MenuItem,
  Menu,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { Info, InsertDriveFileOutlined, MoreVert } from "@mui/icons-material";
// import { MoreVert } from "@mui/icons-material";
// import { getLoggedInUser } from "../../utilities/helper";

import styled from "styled-components";
import { VendorCardTitle } from "../../features/adminOrders/components/adminOrdersViewContent";
import { VendorCardLabel } from "../../features/vendorRegistration/components/StepMain";
import { CustomDocumentLink } from "./styles/card.style";
import { getLoggedInUser } from "../../utilities/helper";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { removeBank } from "../../features/bankDetails/redux/bankDetailsSlice";
import { Super } from "../../features/vendorRegistration/components/steps/cardTrial";
import { LoadingButton } from "@mui/lab";
const BankInfoDetails = ({ item, is_domestic }) => {
  const {
    id,
    bank_name,
    city,
    branch_name,
    account_name,
    account_number,
    is_primary_account,
    currency,
    ifsc_code,
    address_line_one,
    swift_code,
    vendor_id,
  } = item.item;
  const { loading } = useSelector((state) => state.bankdetails);
  const [openDialog, setOpenDialog] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const payload = {
      bank_id: id,
      vid: vendor_id,
      reason: data.reason,
    };
    dispatch(removeBank(payload));
    setOpenDialog(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  if (item.image) {
    var {
      rtgs_authorization_letter_image_name,
      rtgs_authorization_letter_image,
      cancelled_cheque_image_name,
      cancelled_cheque_image,
      bank_statement_image_name,
      bank_statement_image,
      bank_passbook_copy_with_bank_stamp_image_name,
      bank_passbook_copy_with_bank_stamp_image,
      supporting_document_image_name,
      supporting_document_image,
    } = item.image;
  }
  // const handleMoreVertClick = () => {
  //   // Perform your desired action here
  //   alert("Performing action after clicking MoreVertIcon");
  // };
  return (
    <>
      <Card>
        <CardHeader
        // action={
        //   getLoggedInUser().roles[0] === "super admin" ? (
        //     <IconButton aria-label="settings">
        //       <Menu
        //         id="long-menu"
        //         MenuListProps={{
        //           "aria-labelledby": "long-button",
        //         }}
        //         anchorEl={anchorEl}
        //         open={open}
        //         onClose={handleClose}
        //         PaperProps={{
        //           style: {
        //             maxHeight: ITEM_HEIGHT * 4.5,
        //             width: "20ch",
        //           },
        //         }}
        //       >
        //         <MenuItem onClick={(handleClose, () => setOpenDialog(true))}>
        //           {"Delete"}
        //         </MenuItem>
        //       </Menu>
        //       <MoreVert onClick={handleClick} />
        //     </IconButton>
        //   ) : (
        //     ""
        //   )
        // }
        // title={
        //   <BankName style={{ textAlign: "left", padding: "0", margin: "0" }}>
        //     {bank_name}
        //     {is_primary_account ? (
        //       <span
        //         style={{
        //           fontSize: "14px",
        //           borderRadius: "25px",
        //           background: "#D55EE1",
        //           padding: "1px 20px",
        //           width: "93px",
        //           height: "22px",
        //           color: "white",
        //           marginLeft: "10px",
        //         }}
        //       >
        //         Primary
        //       </span>
        //     ) : (
        //       ""
        //     )}
        //   </BankName>
        // }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6} md={4}>
              <Typography color="text.secondary" gutterBottom>
                City
              </Typography>
              <Typography> {city}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography color="text.secondary" gutterBottom>
                Branch Name:
              </Typography>
              <Typography> {branch_name}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography color="text.secondary" gutterBottom>
                A/C name:
              </Typography>
              <Typography> {account_name}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography color="text.secondary" gutterBottom>
                {is_domestic ? "A/C Number:" : "IBA Number:"}
              </Typography>
              <Typography> {account_number}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography color="text.secondary" gutterBottom>
                {is_domestic ? "IFSC Code:" : "Swift Code"}
              </Typography>
              <Typography>
                {" "}
                {is_domestic
                  ? ifsc_code
                    ? ifsc_code
                    : "Not Applicable"
                  : swift_code
                  ? swift_code
                  : "Not Applicable"}
              </Typography>
            </Grid>
            {is_domestic ? (
              <Grid item xs={6} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Address:
                </Typography>
                <Typography>
                  {address_line_one ? address_line_one : "Not Applicable"}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={6} md={4}>
              <Typography color="text.secondary" gutterBottom>
                Currency:
              </Typography>
              <Typography> {currency}</Typography>
            </Grid>
          </Grid>
          <br />
          <VendorCardTitle>Documets:</VendorCardTitle>
          <Grid
            container
            spacing={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6} md={4}>
              <Typography className="label">RTGS DOCUMENT</Typography>
              <VendorCardLabel>
                {rtgs_authorization_letter_image_name ? (
                  rtgs_authorization_letter_image_name ? (
                    <CustomDocumentLink
                      href={rtgs_authorization_letter_image}
                      target="_blank"
                    >
                      <InsertDriveFileOutlined
                        style={{
                          marginTop: "-1px",
                          marginRight: "3px",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      RTGS document
                    </CustomDocumentLink>
                  ) : (
                    <Typography className="label">
                      <Info
                        sx={{
                          marginTop: "-1px",
                          marginRight: "1px",
                          fill: "grey",
                          height: "15px",
                          width: "15px",
                        }}
                      />{" "}
                      There is no RTGS document
                    </Typography>
                  )
                ) : (
                  <Typography className="label">
                    <Info
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no RTGS document
                  </Typography>
                )}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography className="label">
                CANCELLED CHEQUE DOCUMENT
              </Typography>
              <VendorCardLabel>
                {cancelled_cheque_image_name ? (
                  cancelled_cheque_image_name ? (
                    <CustomDocumentLink
                      href={cancelled_cheque_image}
                      target="_blank"
                    >
                      <InsertDriveFileOutlined
                        style={{
                          marginTop: "-1px",
                          marginRight: "3px",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      Cancelled cheque document
                    </CustomDocumentLink>
                  ) : (
                    <Typography className="label">
                      <Info
                        sx={{
                          marginTop: "-1px",
                          marginRight: "1px",
                          fill: "grey",
                          height: "15px",
                          width: "15px",
                        }}
                      />{" "}
                      There is no cancelled cheque document
                    </Typography>
                  )
                ) : (
                  <Typography className="label">
                    <Info
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no cancelled cheque document
                  </Typography>
                )}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography className="label">BANK STATEMENT DOCUMENT</Typography>
              <VendorCardLabel>
                {bank_statement_image_name ? (
                  bank_statement_image_name ? (
                    <CustomDocumentLink
                      href={bank_statement_image}
                      target="_blank"
                    >
                      <InsertDriveFileOutlined
                        style={{
                          marginTop: "-1px",
                          marginRight: "3px",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      Bank statement document
                    </CustomDocumentLink>
                  ) : (
                    <Typography className="label">
                      <Info
                        sx={{
                          marginTop: "-1px",
                          marginRight: "1px",
                          fill: "grey",
                          height: "15px",
                          width: "15px",
                        }}
                      />{" "}
                      There is no Bank statement document document
                    </Typography>
                  )
                ) : (
                  <Typography className="label">
                    <Info
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />
                    There is no Bank statement document document
                  </Typography>
                )}
              </VendorCardLabel>
            </Grid>
          </Grid>
          <br />
          <Grid
            container
            spacing={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6} md={4}>
              <Typography className="label">BANK PASSBOOK DOCUMENT</Typography>
              <VendorCardLabel>
                {bank_passbook_copy_with_bank_stamp_image_name ? (
                  bank_passbook_copy_with_bank_stamp_image_name ? (
                    <CustomDocumentLink
                      href={bank_passbook_copy_with_bank_stamp_image}
                      target="_blank"
                    >
                      <InsertDriveFileOutlined
                        style={{
                          marginTop: "-1px",
                          marginRight: "3px",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      Bank Passbook document
                    </CustomDocumentLink>
                  ) : (
                    <Typography className="label">
                      <Info
                        sx={{
                          marginTop: "-1px",
                          marginRight: "1px",
                          fill: "grey",
                          height: "15px",
                          width: "15px",
                        }}
                      />{" "}
                      There is no bank passbook document
                    </Typography>
                  )
                ) : (
                  <Typography className="label">
                    <Info
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no bank passbook document
                  </Typography>
                )}
              </VendorCardLabel>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography className="label">BANK PASSBOOK DOCUMENT</Typography>
              <VendorCardLabel>
                {supporting_document_image_name ? (
                  supporting_document_image_name ? (
                    <CustomDocumentLink
                      href={supporting_document_image}
                      target="_blank"
                    >
                      <InsertDriveFileOutlined
                        style={{
                          marginTop: "-1px",
                          marginRight: "3px",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      Supporting document
                    </CustomDocumentLink>
                  ) : (
                    <Typography className="label">
                      <Info
                        sx={{
                          marginTop: "-1px",
                          marginRight: "1px",
                          fill: "grey",
                          height: "15px",
                          width: "15px",
                        }}
                      />{" "}
                      There is no supporting document
                    </Typography>
                  )
                ) : (
                  <Typography className="label">
                    <Info
                      sx={{
                        marginTop: "-1px",
                        marginRight: "1px",
                        fill: "grey",
                        height: "15px",
                        width: "15px",
                      }}
                    />{" "}
                    There is no supporting document
                  </Typography>
                )}
              </VendorCardLabel>
            </Grid>

            <Grid item xs={6} md={4}></Grid>
          </Grid>
          <br />
        </CardContent>
      </Card>
      {/* dialog  box*/}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move", fontWeight: "Bold" }}>
          Reason for Deleting Bank Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reason for deletion.<Super>*</Super>
          </DialogContentText>
          <DialogContentText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                variant="standard"
                style={{ width: "100%" }}
                error={Boolean(errors?.reason)}
              >
                <TextField
                  style={{ width: "100%" }}
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter Reason"
                  variant="standard"
                  name="reason"
                  {...register("reason", {
                    required: "please enter reason",
                  })}
                  error={Boolean(errors?.reason)}
                  helperText={errors.reason?.message}
                />
              </FormControl>
              <Grid
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  autoFocus
                  onClick={handleClose}
                  style={{ height: "40px", border: "#4059e5 solid 1px" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    background: "#4059e5",
                    width: "200px",
                    height: "40px",
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                >
                  DELETE
                </LoadingButton>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}></DialogActions>
      </Dialog>
    </>
  );
};

export default BankInfoDetails;

export const BankName = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  /* Neutral/900 */

  color: #212134;
`;
