import React, { useState } from "react";
import { styled as materialStyles } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import editIcon from "../../../../assets/Group 41.svg";
import deleteIcon from "../../../../assets/Group 115.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch } from "react-redux";
import {
  Button,
  Container,
  FormControl,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";
import styled from "styled-components";
import {
  SelectSpan,
  StyledCard,
  StyledFilledButton,
  StyledOutlineButton,
} from "../StepMain";
import { VendorCardTitle } from "./vendorRegEdit";
import { VendorCardLabel } from "./bankRegEdit";
import { GSTvalidate } from "../../redux/vendorRegistrationSlice";
import CloseIcon from "@mui/icons-material/Close";
// import LocalStorage from "../../../../utilities/localStorage";
import { message } from "antd";

const Input = materialStyles("input")({
  display: "none",
});

const ContactCard = ({
  index,
  item,
  register,
  onChange,
  onSave,
  onEdit,
  onDelete,
}) => {
  const { contact_first_name, contact_last_name, email, mobile_number, edit } =
    item;

  return (
    <AddContactDiv>
      {edit ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ padding: "15px 15px 0 15px" }}>
              <ContactCardTitle style={{ marginLeft: "30px" }}>
                Add Contact details
              </ContactCardTitle>
            </Grid>
            <Grid sx={{ padding: "15px 15px 0 15px" }} item xs={6}>
              <ContactCardTitle style={{ textAlign: "right" }}>
                <Close onClick={() => onDelete(index)}>
                  <CloseIcon style={{ cursor: "pointer" }} />
                </Close>
              </ContactCardTitle>
            </Grid>
          </Grid>
          <CardContent>
            <Container fixed>
              <Grid container spacing={3}>
                <Grid item xs={6} md={4}>
                  <VendorCardLabel>Contact person first name</VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    id={`contact_first_name_${index}`}
                    style={{ width: "100%" }}
                    {...register("contact_first_name", {
                      required: "Please enter First name",
                    })}
                    InputProps={{
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    placeholder="Enter first name"
                    value={contact_first_name || ""}
                    onChange={(e) => onChange(e, index)}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <VendorCardLabel>Contact person last name</VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    id={`contact_last_name_${index}`}
                    style={{ width: "100%" }}
                    {...register("contact_last_name", {
                      required: "Please enter last name",
                    })}
                    InputProps={{
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    placeholder="Enter last name"
                    value={contact_last_name || ""}
                    onChange={(e) => onChange(e, index)}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <VendorCardLabel>Contact person email</VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    id={`email_${index}`}
                    style={{ width: "100%" }}
                    {...register("email", {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Entered value does not match email format",
                      },
                      required: "Please enter email",
                    })}
                    InputProps={{
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    placeholder="Enter e-mail"
                    value={email || ""}
                    onChange={(e) => onChange(e, index)}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <VendorCardLabel>Contact person mobile</VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    id={`mobile_number_${index}`}
                    style={{ width: "100%" }}
                    {...register("mobile_number", {
                      pattern: {
                        value: /[0-9]{10}/,
                        message: "Phone number should be 10-digit",
                      },
                    })}
                    InputProps={{
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    placeholder="Enter mobile number"
                    value={mobile_number || ""}
                    onChange={(e) => onChange(e, index)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} md={8}></Grid>
                <Grid item xs={6} md={4}>
                  <StyledFilledButton
                    variant="contained"
                    color="success"
                    style={{ textAlign: "right", float: "right" }}
                    onClick={() => onSave(index)}
                  >
                    Save
                  </StyledFilledButton>
                  <br />
                </Grid>
              </Grid>
            </Container>
          </CardContent>
        </>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs="10">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="3">
                <DarkText>{`${contact_first_name || ""}${
                  contact_last_name ? ` ${contact_last_name}` : ""
                }`}</DarkText>
              </Grid>
              <Grid item xs="1">
                <Dot />
              </Grid>
              <Grid item xs="4">
                {email ? <LightText>{email}</LightText> : null}
              </Grid>
              <Grid item xs="1">
                <Dot />
              </Grid>
              <Grid item xs="3">
                {mobile_number ? <LightText>{mobile_number}</LightText> : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={6}
                style={{ cursor: "pointer" }}
                onClick={() => onEdit(index)}
              >
                <div style={{ cursor: "pointer" }}>
                  <img src={editIcon} alt="edit" />
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ cursor: "pointer" }}
                onClick={() => onDelete(index)}
              >
                <div style={{ cursor: "pointer" }}>
                  <img src={deleteIcon} alt="delete" />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </AddContactDiv>
  );
};

const LocationInfo = ({
  clientId,
  l_index,
  register,
  onChange,
  phone,
  branch_email,
  fax_number,
  state,
  city,
  address_line_one,
  address_line_two,
  postal_code,
  errors,
}) => {
  return (
    <CardContent>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <VendorCardLabel>Country</VendorCardLabel>
            <BankDisabledFields>India</BankDisabledFields>
          </Grid>
          <Grid item xs={12} md={4}>
            <VendorCardLabel>State</VendorCardLabel>
            <BankDisabledFields>{state}</BankDisabledFields>
          </Grid>
          <Grid item xs={12} md={4}>
            <VendorCardLabel>City</VendorCardLabel>
            <BankDisabledFields>{city}</BankDisabledFields>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <VendorCardLabel>Address Line 1</VendorCardLabel>
            <BankDisabledFields>{address_line_one}</BankDisabledFields>
          </Grid>
          <Grid item xs={12} md={4}>
            <VendorCardLabel>Address Line 2</VendorCardLabel>
            <BankDisabledFields>{address_line_two}</BankDisabledFields>
          </Grid>
          <Grid item xs={12} md={4}>
            <VendorCardLabel>Postal Code</VendorCardLabel>
            <BankDisabledFields>{postal_code}</BankDisabledFields>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <VendorCardLabel>
              Phone<Super>*</Super>
            </VendorCardLabel>
            <TextField
              {...register("phone", {
                pattern: {
                  value: /[0-9]{10}/,
                  message: "Phone number should be 10-digit",
                },
                required: "Phone number should be 10-digit",
                value: `${phone}`,
              })}
              sx={{
                input: {
                  fontSize: "14px",
                  fontFamily: "var(--font-family-reg)",
                },
                width: "100%",
              }}
              id={`phone_${l_index}`}
              InputProps={{
                disableInjectingGlobalStyles: true,
              }}
              InputLabelProps={{
                disableAnimation: true,
                disableInjectingGlobalStyles: true,
              }}
              size="small"
              variant="standard"
              placeholder="Enter Phone"
              value={phone || ""}
              onChange={(e) => onChange(e, l_index)}
              disabled={clientId === 2 && phone ? true : false}
              error={Boolean(errors?.phone)}
              helperText={errors.phone?.message}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <VendorCardLabel>
              Branch e-mail for communication <Super>*</Super>
            </VendorCardLabel>
            <TextField
              sx={{
                input: {
                  fontSize: "14px",
                  fontFamily: "var(--font-family-reg)",
                },
              }}
              id={`branch_email_${l_index}`}
              style={{ width: "100%" }}
              {...register("branch_email", {
                required: "Please enter email",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
                value: `${branch_email}`,
              })}
              InputProps={{
                disableInjectingGlobalStyles: true,
              }}
              InputLabelProps={{
                disableAnimation: true,
                disableInjectingGlobalStyles: true,
              }}
              size="small"
              variant="standard"
              placeholder="Enter email"
              value={branch_email || ""}
              onChange={(e) => onChange(e, l_index)}
              error={Boolean(errors?.branch_email)}
              helperText={errors.branch_email?.message}
              disabled={clientId === 2 && branch_email ? true : false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <VendorCardLabel>FAX number</VendorCardLabel>
            <TextField
              sx={{
                input: {
                  fontSize: "14px",
                  fontFamily: "var(--font-family-reg)",
                },
              }}
              id={`fax_number_${l_index}`}
              style={{ width: "100%" }}
              {...register("fax_number")}
              InputProps={{
                disableInjectingGlobalStyles: true,
              }}
              InputLabelProps={{
                disableAnimation: true,
                disableInjectingGlobalStyles: true,
              }}
              size="small"
              variant="standard"
              placeholder="Enter FAX"
              value={fax_number || ""}
              onChange={(e) => onChange(e, l_index)}
              disabled={clientId === 2 && phone ? true : false}
            />
          </Grid>
        </Grid>
      </Container>
    </CardContent>
  );
};

const ExpandMore = materialStyles((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Close = materialStyles((props, index) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({
  l_index,
  location_item,
  register,
  expanded,
  handleExpandClick,
  contacts,
  setContacts,
  onChange,
  onDelete,
  errors,
  registerType,
  clientId,
  is_domestic,
}) {
  const {
    gst_id,
    location,
    phone,
    branch_email,
    fax_number,
    country,
    state,
    city,
    address_line_one,
    address_line_two,
    postal_code,
    gst_document_image_name,
    gst_document_image,
  } = location_item;
  const [preview, setPreview] = useState("");
  const [dropdownValue, setdropdownValue] = useState(location);
  const handleAddContact = () => {
    let contactsCopy = [...contacts];

    contactsCopy.push({
      contact_first_name: "",
      contact_last_name: "",
      email: "",
      mobile_number: "",
      edit: true,
    });

    setContacts(contactsCopy);
  };

  const handleContactOnchange = (e, index) => {
    const { name, value } = e.target;

    let contactsCopy = [...contacts];

    contactsCopy[index] = {
      ...(contactsCopy[index] || {}),
      [name]: value,
    };

    setContacts(contactsCopy);
  };

  const [selectedlocation, setSelectedlocation] = useState({});

  // var user = LocalStorage.getItem("tata_login_user");
  // const is_domestic = user.is_domestic;

  const handleLocationOnchange = (e, index) => {
    const unique = gstLocations[index].addresses.find(
      (aLeague) =>
        aLeague.addr.loc + aLeague.addr.st + aLeague.addr.pncd ===
        e.target.value
    );
    setPreview(e.target.value);
    setdropdownValue(unique?.addr?.loc);
    setSelectedlocation(unique);
    onChange(e, index);
  };
  if (selectedlocation?.addr) {
    location_item["location"] = selectedlocation.addr
      ? dropdownValue
      : location;
    location_item["state"] = selectedlocation.addr
      ? selectedlocation.addr.stcd
      : state;
    location_item["city"] = selectedlocation.addr
      ? selectedlocation.addr.dst
      : city;
    location_item["address_line_one"] = selectedlocation.addr
      ? `${selectedlocation?.addr?.flno} ${selectedlocation?.addr?.bno} ${selectedlocation?.addr?.bnm}`
      : address_line_one;
    location_item["address_line_two"] = selectedlocation.addr
      ? `${selectedlocation?.addr?.st} ${selectedlocation?.addr?.landMark} ${selectedlocation?.addr?.locality}`
      : address_line_two;
    location_item["postal_code"] = selectedlocation.addr
      ? selectedlocation.addr.pncd
      : postal_code;
    location_item["country"] = is_domestic === true ? "India" : country;
  }
  const handleSaveContact = (index) => {
    let contactsCopy = [...contacts];
    contactsCopy.map((contact, index) => {
      const { contact_first_name, contact_last_name, email, mobile_number } =
        contact;
      if (
        contact_first_name === "" ||
        contact_last_name === "" ||
        email === "" ||
        mobile_number === ""
      ) {
        contactsCopy.splice(index, 1);
        return message.error("Contact all field are require");
      }
      contactsCopy[index] = {
        ...(contactsCopy[index] || {}),
        edit: false,
      };

      setContacts(contactsCopy);
      // eslint-disable-next-line array-callback-return
      return;
    });
  };

  const handleEditContact = (index) => {
    let contactsCopy = [...contacts];

    contactsCopy[index] = {
      ...(contactsCopy[index] || {}),
      edit: true,
    };

    setContacts(contactsCopy);
  };

  const handleDeleteContact = (index) => {
    let contactsCopy = [...contacts];
    // eslint-disable-next-line
    contactsCopy = contactsCopy.filter((_, i) => i != index);

    setContacts(contactsCopy);
  };
  const [gstLocations, setGstLocations] = useState([]);

  const dispatch = useDispatch();
  const gstin = { gstin: gst_id };
  const validateGST = (e, index) => {
    const GSTRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    if (GSTRegex.test(gst_id)) {
      dispatch(GSTvalidate(gstin)).then((data) => {
        if (data.payload.success === true) {
          const data1 = data.payload.gst_api_result.data
            ? data.payload.gst_api_result.data.adadr
            : [];

          const data2 = data.payload.gst_api_result.data
            ? data.payload.gst_api_result.data.pradr
            : [];

          const addresses = [...data1, data2];
          let gstCopy = [...gstLocations];
          gstCopy[index] = {
            ...(gstCopy[index] || {}),
            addresses,
          };

          setGstLocations(gstCopy);
        }
      });
    } else {
      message.error("Please enter valid GST number");
    }
  };

  return (
    <StyledCard>
      <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={8}>
              <VendorCardTitle>
                {clientId === 2 ? (
                  "Business location"
                ) : (
                  <>
                    Business location {l_index + 1}
                    <DeleteOuterContainer onClick={() => onDelete(l_index)}>
                      <img src={deleteIcon} alt="del" />
                    </DeleteOuterContainer>
                  </>
                )}
              </VendorCardTitle>
            </Grid>
            <Grid item xs={6} md={4}>
              <ExpandMore
                expand={expanded}
                onClick={() => handleExpandClick(l_index)}
                aria-expanded={expanded}
                aria-label="show more"
                style={{ textAlign: "right", float: "right" }}
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Grid>
          </Grid>
        </Box>
      </CardContent>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {is_domestic === true && registerType !== "unregistered" ? (
            <>
              <BusinessRegistrationSubTitle>
                GST information
              </BusinessRegistrationSubTitle>
              <br />
              <Grid>
                <VendorCardLabel>
                  GST ID <Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  id={`gst_id_${l_index}`}
                  {...register("gst_id", {
                    required: "Please enter GST number",
                    value: `${gst_id}`,
                    pattern: {
                      value:
                        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                      message: "Please valid enter GST number",
                    },
                  })}
                  error={errors?.gst_id}
                  helperText={errors?.gst_id?.message}
                  InputProps={{
                    disableInjectingGlobalStyles: true,
                  }}
                  InputLabelProps={{
                    disableAnimation: true,
                    disableInjectingGlobalStyles: true,
                  }}
                  size="small"
                  variant="standard"
                  placeholder="Enter GST and validate"
                  style={{ marginRight: "25px", width: "32%" }}
                  value={gst_id || ""}
                  disabled={gst_id && clientId === 2 ? true : false}
                  onChange={(e) => onChange(e, l_index)}
                />

                <Button
                  sx={{
                    background: "var(--button-color)",
                  }}
                  color="success"
                  variant="contained"
                  // type="submit"
                  disabled={clientId !== 2 && gst_id ? false : true}
                  onClick={(e) => validateGST(e, l_index)}
                >
                  Validate
                </Button>
              </Grid>
              <br />
              <Grid>
                <FormControl
                  variant="standard"
                  style={{ marginRight: "25px", width: "32%" }}
                  error={Boolean(errors?.gst_document_image_name)}
                >
                  <VendorCardLabel
                    style={{ fontWeight: "500", fontSize: "14px" }}
                  >
                    Upload GST Document<Super>*</Super>
                    {gst_document_image_name ? (
                      <>
                        <span
                          style={{
                            color: "#4945FF",
                            fontSize: "13px",
                            fontFamily: "var(--font-family-reg)",
                          }}
                        >
                          <Chip
                            style={{
                              width: "150px",
                              color: "#4945FF",
                              margin: "5px",
                              borderRadius: "5px",
                              border: "1px solid #D9D8FF",
                              background: "#F0F0FF",
                            }}
                            label={
                              gst_document_image_name?.name
                                ? gst_document_image_name?.name
                                : gst_document_image_name
                            }
                            {...((gst_document_image || "").includes("http")
                              ? {
                                  component: "a",
                                  clickable: true,
                                  href: gst_document_image,
                                  target: "_blank",
                                }
                              : {})}
                          />
                          {clientId !== 2 && (
                            <>
                              <HighlightOffIcon
                                sx={{ color: "#4945FF", paddingBottom: "2px" }}
                                fontSize="medium"
                                onClick={() =>
                                  onChange(
                                    {
                                      target: {
                                        name: "gst_document_image_name",
                                        value: "",
                                      },
                                    },
                                    l_index
                                  )
                                }
                              />
                              <span>Remove </span>
                            </>
                          )}
                        </span>
                      </>
                    ) : (
                      <label htmlFor="gst_document_image_name">
                        <Input
                          {...register("gst_document_image_name", {
                            required: "Please upload GST copy",
                          })}
                          accept="*"
                          id="gst_document_image_name"
                          onChange={(e) =>
                            onChange(
                              {
                                target: {
                                  name: "gst_document_image_name",
                                  value: e.target.files[0],
                                },
                              },
                              l_index
                            )
                          }
                          type="file"
                        />
                        <StyledOutlineButton
                          style={{ marginLeft: "20px" }}
                          variant="outlined"
                          component="span"
                        >
                          Upload
                        </StyledOutlineButton>
                      </label>
                    )}
                    {gst_document_image_name === " " ? (
                      ""
                    ) : (
                      <FormHelperText>
                        {errors.gst_document_image_name?.message}
                      </FormHelperText>
                    )}
                  </VendorCardLabel>
                </FormControl>
              </Grid>

              <br />
              <br />
              <Grid>
                <FormControl
                  variant="standard"
                  style={{ marginRight: "25px", width: "32%" }}
                >
                  <VendorCardTitle>Location information</VendorCardTitle>
                  <VendorCardLabel>
                    Select location <Super>*</Super>
                  </VendorCardLabel>
                  {clientId !== 2 && gst_id ? (
                    <Select
                      sx={{
                        fontFamily: "var(--font-family-reg)",
                        fontSize: "14px",
                      }}
                      displayEmpty
                      defaultValue=""
                      IconComponent={() => (
                        <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                      )}
                      id={`location_${l_index}`}
                      {...register("location", {
                        required: true,
                        value: `${dropdownValue}`,
                      })}
                      value={preview || ""}
                      onChange={(e) => handleLocationOnchange(e, l_index)}
                    >
                      <MenuItem value="">
                        <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                          Select location
                        </span>
                      </MenuItem>

                      {gstLocations[l_index] &&
                      gstLocations[l_index]?.addresses ? (
                        gstLocations[l_index].addresses.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={
                              item.addr.loc + item.addr.st + item.addr.pncd ??
                              dropdownValue
                            }
                          >
                            <SelectSpan>{`${item.addr.loc}, ${item.addr.st} (${item.addr.pncd})`}</SelectSpan>
                          </MenuItem>
                        ))
                      ) : // eslint-disable-next-line
                      location_item != "" ? (
                        <MenuItem value={location_item.location}>
                          <SelectSpan>{location_item.location}</SelectSpan>
                        </MenuItem>
                      ) : (
                        ""
                      )}
                    </Select>
                  ) : (
                    <span>{location_item?.location}</span>
                  )}
                </FormControl>
              </Grid>
              <br />

              {location ? (
                <LocationInfo
                  clientId={clientId}
                  l_index={l_index}
                  register={register}
                  onChange={onChange}
                  phone={phone}
                  branch_email={branch_email}
                  fax_number={fax_number}
                  state={
                    selectedlocation?.addr
                      ? selectedlocation?.addr?.stcd
                      : location_item.state
                  }
                  city={
                    selectedlocation?.addr
                      ? selectedlocation?.addr?.dst
                      : location_item.city
                  }
                  address_line_one={
                    selectedlocation?.addr
                      ? `${selectedlocation?.addr?.flno} ${selectedlocation?.addr?.bno} ${selectedlocation?.addr?.bnm}`
                      : location_item.address_line_one
                  }
                  address_line_two={
                    selectedlocation?.addr
                      ? `${selectedlocation?.addr?.st} ${selectedlocation?.addr?.landMark} ${selectedlocation?.addr?.locality}`
                      : location_item.address_line_two
                  }
                  postal_code={
                    selectedlocation?.addr
                      ? selectedlocation?.addr?.pncd
                      : location_item.postal_code
                  }
                  errors={errors}
                />
              ) : null}
            </>
          ) : (
            <CardContent>
              <Container fixed>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <VendorCardLabel>
                      Branch<Super>*</Super>
                    </VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      id={`location_${l_index}`}
                      style={{ width: "100%" }}
                      {...register("location")}
                      error={Boolean(errors?.location)}
                      helperText={errors.location?.message}
                      InputProps={{
                        disableInjectingGlobalStyles: true,
                      }}
                      InputLabelProps={{
                        disableAnimation: true,
                        disableInjectingGlobalStyles: true,
                      }}
                      size="small"
                      variant="standard"
                      placeholder="Enter Branch name"
                      name="location"
                      value={location || ""}
                      onChange={(e) => onChange(e, l_index)}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <VendorCardLabel>Country</VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      id={`country_${l_index}`}
                      style={{ width: "100%" }}
                      {...register("country")}
                      InputProps={{
                        disableInjectingGlobalStyles: true,
                      }}
                      InputLabelProps={{
                        disableAnimation: true,
                        disableInjectingGlobalStyles: true,
                      }}
                      size="small"
                      variant="standard"
                      placeholder="Enter Country name"
                      value={country || ""}
                      onChange={(e) => onChange(e, l_index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <VendorCardLabel>
                      State<Super>*</Super>
                    </VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      id={`state_${l_index}`}
                      style={{ width: "100%" }}
                      {...register("state")}
                      error={Boolean(errors?.state)}
                      helperText={errors.state?.message}
                      InputProps={{
                        disableInjectingGlobalStyles: true,
                      }}
                      InputLabelProps={{
                        disableAnimation: true,
                        disableInjectingGlobalStyles: true,
                      }}
                      size="small"
                      variant="standard"
                      placeholder="Enter state name"
                      value={state || ""}
                      onChange={(e) => onChange(e, l_index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <VendorCardLabel>City</VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      id={`city_${l_index}`}
                      style={{ width: "100%" }}
                      {...register("city", {
                        required: "Plaese enter city ",
                        value: city || "",
                      })}
                      error={Boolean(errors?.city)}
                      helperText={errors.city?.message}
                      InputProps={{
                        disableInjectingGlobalStyles: true,
                      }}
                      InputLabelProps={{
                        disableAnimation: true,
                        disableInjectingGlobalStyles: true,
                      }}
                      size="small"
                      variant="standard"
                      placeholder="Enter city"
                      value={city || ""}
                      onChange={(e) => onChange(e, l_index)}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <VendorCardLabel>Address Line 1</VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      id={`address_line_one_${l_index}`}
                      style={{ width: "100%" }}
                      {...register("address_line_one")}
                      error={Boolean(errors?.address_line_one)}
                      helperText={errors.address_line_one?.message}
                      InputProps={{
                        disableInjectingGlobalStyles: true,
                      }}
                      InputLabelProps={{
                        disableAnimation: true,
                        disableInjectingGlobalStyles: true,
                      }}
                      size="small"
                      variant="standard"
                      placeholder="Enter address line 1"
                      value={address_line_one || ""}
                      onChange={(e) => onChange(e, l_index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <VendorCardLabel>Address Line 2</VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      id={`address_line_two_${l_index}`}
                      style={{ width: "100%" }}
                      {...register("address_line_two")}
                      InputProps={{
                        disableInjectingGlobalStyles: true,
                      }}
                      InputLabelProps={{
                        disableAnimation: true,
                        disableInjectingGlobalStyles: true,
                      }}
                      size="small"
                      variant="standard"
                      placeholder="Enter address line2"
                      value={address_line_two || ""}
                      onChange={(e) => onChange(e, l_index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <VendorCardLabel>Postal Code</VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      id={`postal_code_${l_index}`}
                      style={{ width: "100%" }}
                      {...register("postal_code")}
                      InputProps={{
                        disableInjectingGlobalStyles: true,
                      }}
                      InputLabelProps={{
                        disableAnimation: true,
                        disableInjectingGlobalStyles: true,
                      }}
                      size="small"
                      variant="standard"
                      placeholder="Enter postal code"
                      value={postal_code || ""}
                      onChange={(e) => onChange(e, l_index)}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <VendorCardLabel>
                      Phone<Super>*</Super>
                    </VendorCardLabel>

                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      id={`phone_${l_index}`}
                      style={{ width: "100%" }}
                      {...register("phone", {
                        pattern: {
                          value: /^\+?\d{8,15}$/,
                          message: "enter valid phone number",
                        },
                        value: `${location_item.phone}`,
                      })}
                      error={Boolean(errors?.phone)}
                      helperText={errors.phone?.message}
                      InputProps={{
                        disableInjectingGlobalStyles: true,
                      }}
                      InputLabelProps={{
                        disableAnimation: true,
                        disableInjectingGlobalStyles: true,
                      }}
                      size="small"
                      variant="standard"
                      placeholder="Enter Phone"
                      value={phone ? phone : location_item.phone || ""}
                      onChange={(e) => onChange(e, l_index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <VendorCardLabel>
                      Branch e-mail for communication <Super>*</Super>
                    </VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      id={`branch_email_${l_index}`}
                      style={{ width: "100%" }}
                      {...register("branch_email", {
                        required: "Please enter email",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                        value: branch_email,
                      })}
                      InputProps={{
                        disableInjectingGlobalStyles: true,
                      }}
                      InputLabelProps={{
                        disableAnimation: true,
                        disableInjectingGlobalStyles: true,
                      }}
                      size="small"
                      variant="standard"
                      placeholder="Enter email"
                      value={branch_email || ""}
                      onChange={(e) => onChange(e, l_index)}
                      error={Boolean(errors?.branch_email)}
                      helperText={errors.branch_email?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <VendorCardLabel>FAX number</VendorCardLabel>
                    <TextField
                      sx={{
                        input: {
                          fontSize: "14px",
                          fontFamily: "var(--font-family-reg)",
                        },
                      }}
                      id={`fax_number_${l_index}`}
                      style={{ width: "100%" }}
                      {...register("fax_number")}
                      InputProps={{
                        disableInjectingGlobalStyles: true,
                      }}
                      InputLabelProps={{
                        disableAnimation: true,
                        disableInjectingGlobalStyles: true,
                      }}
                      size="small"
                      variant="standard"
                      placeholder="Enter FAX"
                      value={fax_number || ""}
                      onChange={(e) => onChange(e, l_index)}
                    />
                  </Grid>
                </Grid>
              </Container>
            </CardContent>
          )}
          <br />
          <Grid>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={8}>
                  <VendorCardTitle>Contacts</VendorCardTitle>
                </Grid>
                <Grid item xs={6} md={4}>
                  <StyledOutlineButton
                    onClick={handleAddContact}
                    style={{ textAlign: "right", float: "right" }}
                  >
                    Add Contact
                  </StyledOutlineButton>
                  <br />
                </Grid>
              </Grid>
            </Box>
            <VendorCardSubTitle>
              {contacts
                ? ""
                : "No contacts added yet. Start adding contacts by clicking on Add contact button in the right."}
            </VendorCardSubTitle>
            {contacts
              ? contacts.map((item, index) => (
                  <ContactCard
                    key={index}
                    index={index}
                    item={item}
                    register={register}
                    onChange={handleContactOnchange}
                    onSave={handleSaveContact}
                    onEdit={handleEditContact}
                    onDelete={handleDeleteContact}
                  />
                ))
              : ""}
          </Grid>
        </CardContent>
      </Collapse>
    </StyledCard>
  );
}

export const BusinessRegistrationSubTitle = styled.div`
  font-family: var(--font-family-semi-bold);
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #212134;
`;

export const Super = styled.sup`
  color: red;
`;

export const VendorCardSubTitle = styled.div`
  margin-top: 10px;
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #666687;
`;

export const AddContactDiv = styled.div`
  padding: 10px;
  text-align: left;
  font-family: var(--font-family-reg);
  background: #f5f7f9;
  border: 1px solid #ebeff3;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const ContactCardTitle = styled.div`
  margin: 10px;
  font-family: var(--font-family-semi-bold);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
`;

export const ContactIconOuterContianer = styled.div`
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ebeff3;
  border-radius: 4px;
  /* padding: 7px; */
  padding-left: 6px;
  width: 28px;
  height: 28px;
`;

export const Dot = styled.div`
  background: #a5a5ba;
  height: 4px;
  width: 4px;
  border-radius: 100%;
`;

export const DarkText = styled.span`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const LightText = styled.span`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #4a4a6a;
`;

export const BankDisabledFields = styled.div`
  font-family: var(--font-family-reg);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Neutral/900 */

  color: #212134;
`;

export const DeleteOuterContainer = styled.span`
  cursor: pointer;
  background: #f5f7f9;
  border: 1px solid #ebeff3;
  border-radius: 4px;
  /* padding: 7px; */
  padding-left: 6px;
  padding-right: 6px;
  width: 28px;
  padding-bottom: 3px;
  padding-top: 0px;

  height: 28px;
  margin-left: 10px;
`;
