import loginReducer from "../features/login/redux/loginSlice";
import signupReducer from "../features/signup/redux/signup/signupSlice";
import forgotPasswordReducer from "../features/forgotPassword/redux/forgotPasswordSlice";
import panReducer from "../features/signup/redux/pan/panSlice";
import vendorRegistrationReducer from "../features/vendorRegistration/redux/vendorRegistrationSlice";
// import addContactReducer from "../features/vendorRegistration/redux/addContactSlice";
import createNewReducer from "../features/createNewPassword/redux/createNewPasswordSlice";
import bankdetailsReducer from "../features/bankDetails/redux/bankDetailsSlice";
import dashBoardDetailsReducer from "../features/dashboard/redux/dashboardSlice";
import getPendingReducer from "../features/adminTable/redux/adminTableSlice";
import changePasswordReducer from "../features/accountSettings/redux/accountSettingsSlice";
import adminOrdersReducer from "../features/adminOrders/redux/adminOrdersSlice";
import invoiceModuleReducer from "../features/invoiceModule/redux/invoiceModuleSlice";
import userOrdersReducer from "../features/userOrders/redux/userOrdersSlice";
import adminInvoicesReducer from "../features/adminInvoices/redux/adminInvoicesSlice";
import companyReducer from "../features/company/redux/companySlice";
import companiesUserReducer from "../features/companiesUser/redux/companiesUserSlice";
import sendInvitationReducer from "../features/adminTable/redux/sendLinkSlice";
import addGlobleFinance from "../features/admin_authirities/redux/AdminAuthoritiesSlice";
import notificationReducer from "../features/notification/redux/notificationSlice";
import { api } from "../utilities/redux-api/api";
import { vendor_category_api } from "../utilities/redux-api/vendor_category/api";
import { sap_api } from "../utilities/redux-api/sap/sap_api";

const rootReducer = {
  login: loginReducer,
  signup: signupReducer,
  forgotPassword: forgotPasswordReducer,
  pan: panReducer,
  vendorRegistration: vendorRegistrationReducer,
  createNewPassword: createNewReducer,
  bankdetails: bankdetailsReducer,
  dashboarddetails: dashBoardDetailsReducer,
  pendinglist: getPendingReducer,
  changepassword: changePasswordReducer,
  adminOrders: adminOrdersReducer,
  invoiceModule: invoiceModuleReducer,
  userOrders: userOrdersReducer,
  adminInvoices: adminInvoicesReducer,
  company: companyReducer,
  companiesUser: companiesUserReducer,
  sendInvitationLink: sendInvitationReducer,
  addGlobleFinance: addGlobleFinance,
  notification: notificationReducer,
  [api.reducerPath]: api.reducer,
  [vendor_category_api.reducerPath]: vendor_category_api.reducer,
  [sap_api.reducerPath]: sap_api.reducer,
};
export default rootReducer;
