import { Autocomplete, Chip, TextField } from "@mui/material";
import { message } from "antd";
import React from "react";
import { hasDuplicates } from "../../utilities/helper";
import { Controller } from "react-hook-form";

const AutoComplate = ({
  data: data1,
  placeholder,
  title,
  isMultiple,
  setValue,
}) => {
  const data = data1.map((data) => {
    return {
      title: data[title],
      id: data.id,
    };
  });
  const handleChange = (_, value) => {
    if (isMultiple) {
      const isDuplicate = hasDuplicates(
        value,
        [],
        ["userId", "userName", "companyName", "isMapp", "logisticMapId"]
      );
      if (isDuplicate) {
        return message.error(`${title} already selected`);
      }

      setValue(value);
    } else {
      setValue(value);
    }
  };

  return (
    <Autocomplete
      multiple={isMultiple}
      id="tags-filled"
      options={data}
      getOptionLabel={(option) => option.title}
      onChange={handleChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.title}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          sx={{
            fontFamily: "var(--font-family-reg)",
            fontSize: "14px",
          }}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default AutoComplate;

export const GlobalAutocomplete = ({
  name,
  control,
  options,
  label,
  rules,
  placeholder,
  defaultValue,
  isMultiple = false,
  onSelect,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Autocomplete
            multiple={isMultiple}
            id="tags-filled"
            options={options}
            getOptionLabel={(option) => option.value}
            onChange={(event, newValue) => {
              onChange(newValue);
              onSelect(newValue);
            }}
            {...props}
            value={value || (isMultiple ? [] : null)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="standard"
                sx={{
                  fontFamily: "var(--font-family-reg)",
                  fontSize: "14px",
                }}
                {...props}
                placeholder={placeholder}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        );
      }}
    />
  );
};
